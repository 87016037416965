@import "assets/sass/netop/_mixin.scss";

.graph-type-selector-icon {
  margin-left: 1rem;
  & svg {
    width: 42px;
    height: 42px;
  }
}
.arrow-graph-type {
  @extend .arrow-reports !optional;
  max-width: 57%;
  margin-left: 0;
}
.graph-type-selector-counters {
  font-size: 14px;
  max-width: 5rem !important;
  @include macbook-pro {
    max-width: 6rem !important;
  }
  &-hide-ill {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
