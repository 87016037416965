$menu-padding-sides: 1.8vw;

$red: #f31c0f;
$green: #38a7b1;
$black: black;
$purple: #9268F8;
$shadow-box: #0000001A;
$light-purple: #E0D3FF;
$light-gray: #f9f8f6;
$dark-purple: #530ea1;
$heavy-dark-purple: #33066B;
$white: white;
$cream-white: #F8F8F0;
