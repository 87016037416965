@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/corporate';
@import 'variables';

$nb-themes: nb-register-theme(
  (
    layout-padding: 0,
    icon-font-size: 32px,
    user-medium-name-text-font-size: 16px,
    user-medium-name-text-icon-size: 28px,
    user-medium-height: 40px,
    user-medium-width: 40px,
    header-height: 65px,
    left-menu-foreground-color: #35076f,
    menu-text-font-family: "'system-ui', sans-serif",
    menu-text-font-size: 15px,
    menu-text-font-weight: 500,
    menu-text-line-height: 19px,
    menu-item-icon-width: 20.5px,
    menu-item-icon-color: var(--left-menu-foreground-color),
    menu-text-color: var(--left-menu-foreground-color),
    header-padding: 1.8vw,
    menu-item-padding: 15px var(--header-padding),
    sidebar-width: 14rem,
    sidebar-width-compact: calc(2 * $menu-padding-sides + var(--menu-item-icon-width) + 0.5rem),
    menu-item-active-background-color: #ecedfe,
    menu-item-active-text-color: rgb(55, 9, 115),
    menu-item-hover-background-color: var(--menu-item-active-background-color),
    menu-item-hover-text-color: var(--menu-item-active-text-color),
    tooltip-text-font-size: 16px,
    tooltip-text-line-height: normal
  ),
  corporate,
  corporate
);

.menu-item a {
  text-transform: capitalize;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.black {
  color: $black;
}

.purple {
  color: $purple;
}
